import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'authorExpandButton', 'authorCollapseButton', 'allAuthors', 'authorMobileContent', 'truncatedAuthors',
    'contentExpandButton', 'metadataContent', 'metadataWrapper', 'sectionWrapper'
  ]

  view() {
    this.allAuthorsTarget.classList.toggle('hidden')
    this.truncatedAuthorsTarget.classList.toggle('hidden')
    this.authorExpandButtonTarget.classList.toggle('hidden')
    this.authorCollapseButtonTarget.classList.toggle('hidden')
  }

  toggleContent() {
    this.metadataContentTarget.classList.toggle('hidden')
  }

  toggleMobileContent() {
    this.metadataWrapperTarget.classList.toggle('visible')
    this.sectionWrapperTarget.classList.toggle('collapsed')
  }
}
