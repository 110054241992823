import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="capi"
export default class extends Controller {
  connect() {
    this.trackTrialPageEvent()
  }

  trackTrialPageEvent() {
    if (typeof fbq === 'function') {
      fbq('trackCustom', 'Visited Trial Page')
    } else {
      setTimeout(() => this.trackTrialPageEvent(), 1000)
    }
  }
}
